<template>
  <div>
    <supervisor v-if="userRole === 'Supervisor'" />
    <admin v-if="userRole === 'Admin'" />
    <accountant v-if="userRole === 'Könyvelő'" />
    <client v-if="userRole === 'Ügyfél'" />
  </div>
</template>

<script>
import Supervisor from "@/views/pages/dashboard/Supervisor.vue";
import Admin from "@/views/pages/dashboard/Admin.vue";
import Accountant from "@/views/pages/dashboard/Accountant.vue";
import Client from "@/views/pages/dashboard/Client.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    Supervisor,
    Admin,
    Accountant,
    Client,
  },
  computed: {
    // eslint-disable-next-line no-undef
    ...mapGetters([
      'userRole',
    ]),
  },
}
</script>
