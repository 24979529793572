<template>
  <div>
    <b-card>
      supervisor vezérlőpult
    </b-card>
  </div>
</template>

<script>
import {BCard} from "bootstrap-vue";

export default {
  components: {
    BCard
  }
}
</script>

<style scoped>

</style>
